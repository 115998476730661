<template>
  <div>
    <div class="cardWrap" v-if="table.total > 0">
      <div class="stepWrap">
        <el-card :body-style="{ padding: '0px' }" v-for="(item,index) in table.data" :key="index" shadow="hover" @click.native="handleStep(item,index)" :class="setpIndex == index ? 'step active' : 'step'">
          <div style="padding: 14px;">
            <div class="stepTitle">{{item.title}}</div>
          </div>
        </el-card>
      </div>
    </div>
    <div>
      <el-divider></el-divider>
      <div class="content" v-if="table.total > 0">
        <template v-if="currRow.child.length > 0">
          <div class="left" >
            <div style="font-weight:bold;margin-bottom:10px;">
              <span>标题</span>
            </div>
            <div :class="itemIndex == i ? 'listTitle itemActive' :'listTitle'" v-for="(v,i) in currRow.child" :key="v.id" @click="handleItem(v,i)">
              <span>{{i+1}}、{{v.title}}</span>
            </div>
          </div>
          <div class="right">
            <div v-html="form.data.content"></div>
          </div>
        </template>
        <div v-else style="width:100%;text-align:center;min-height:500px;">暂未添加教程</div>
      </div>
      <div v-else style="text-align:center;min-height:500px;">暂未添加教程</div>
    </div>
    
  </div>
</template>

<script>
import { mixinTable } from '@/mixins/table.js'
export default {
  name: '',
  mixins: [mixinTable],
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      isChange: false, // 编辑时为true
      setpIndex: 0, // 步骤点击后的索引
      table: {
        loading: false,
        params: {
          page:1,
          count: 10
        },
        data: [],
        total:0
      },
      form: {
        loading: false,
        data:{
          content: ''
        },
      },
      // 当前选择的步骤
      currRow: {
        id: '',
        title: '',
        child: [],
      },
      itemIndex: 0,
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.get('/admin/course/list', {params: this.table.params}).then(res => {
        if(res.code == 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.currRow = res.data.list[this.setpIndex];
          if(this.currRow.child.length > 0) {
            this.form.data.content = this.table.data[this.setpIndex].child[this.itemIndex].content;
          } else {
            this.form.data.content = '';
          }
        }
      })
    },
    // 点击切换步骤
    handleStep(row,index) {
      this.setpIndex = index; //步骤索引
      this.currRow = row; // 当前步骤对象
      this.itemIndex = 0; // 切换步骤时教程索引归0
      // 切换步骤时，如该步骤下有教程，则富文本默认展示该步骤下的第一个教程，否则富文本置空
      if(row.child.length > 0) {
        this.form.data.content = row.child[0].content;
      } else {
        this.form.data.content = '';
      }
    },
    // 点击切换教程
    handleItem(v,i) {
      this.itemIndex = i; // 教程索引
      this.form.data.content = v.content;
    },
    // 添加新教程时清空富文本
    handleAdd() {
      this.form.data.content = '';
    }
  }
}
</script>


<style scoped>
.cardWrap {
  display: flex;
  align-items: end;
  justify-content: center;
}
.stepWrap {
  display: flex;
  flex-wrap: wrap;
}
.step {
  cursor: pointer;
  margin-top: 10px;
}
.stepTitle {
  cursor: pointer;
}
.active {
  color: #2cb167;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  background:#eaf7f0 !important;
}
.clearfix {
  text-align: right;
  margin-top: 20px;
}
.step {
  width: 200px;
  height: 106px;
  margin-right: 20px;
  background: #eef6fd;
  display: flex;
  align-items: center;
}
.listTitle {
  height: 40px;
  line-height: 40px;
  padding: 0 5px;
  cursor: pointer;
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 显示省略号 */
}
.listTitle:hover {
  color: #2cb167;
}
.itemActive {
  color: #2cb167;
  background: #f7fffa;
  border: solid 1px #eaf7f0;
}

.content {
  display: flex;
}
.left {
  width: 40%;
  max-width: 45px;
  min-width: 450px;
  margin-right: 20px;
  overflow-y: scroll;
}
.right {
  flex: 1;
  overflow-y: scroll;
  padding: 10px;
  height: 500px;
  box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6), 0 2px 4px 0 rgba(232, 237, 250, 0.5);
}
::-webkit-scrollbar {
  width: 0px; /* 设置滚动条宽度 */
}
</style>