<template>
  <div class="main-view">
    <div class="componentsWrap">
      <el-radio-group v-model="table.params.status" class="tabsWrap" @change="changePage">
        <el-radio-button label="guide">新手引导</el-radio-button>
        <el-radio-button label="help">功能帮助</el-radio-button>
      </el-radio-group>
      
      <components :is="activeName"></components>
    </div>
  </div>
</template>

<script>
import guide from './guide/index.vue'
import help from './help/index.vue'
export default {
  name: 'helpIndex',
  components: {
    guide,
    help,
  },
  data() {
    return {
      table: {
        params:{
          status: "guide"
        }
      },
      activeName: 'guide'
    }
  },
  methods: {
    changePage(value) {
      this.activeName = value;
    },
   
  }
}
</script>

<style scoped>
.componentsWrap {
  background: #fff;
  padding: 24px;
}

</style>