<template>
  <div>
    <div v-if="table.total > 0">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize" class="mt10" @submit.native.prevent>
        <el-form-item label="" prop="">
          <span :class="sortIndex == 1 ? 'sort sortActive' : 'sort'" @click="handleSort(1)">最新</span>
          <span :class="sortIndex == 2 ? 'sort sortActive' : 'sort'" style="margin-left:-20px;" @click="handleSort(2)">热门</span>
        </el-form-item>
        <el-form-item label="" prop="keyword">
          <el-input
            size="mini"
            v-model.trim="table.params.keyword"
            placeholder="请输入"
            clearable
            @keydown.enter.native="getTable"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="getTable">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="content flex">
        <div class="left">
          <div :class="itemIndex == index ? 'list active' : 'list'" v-for="(item,index) in table.data" :key="index" @click="handleList(item,index)">
            <div class="img">
              <el-image
                v-if="item.file_type == 1"
                fit="cover"
                style="width: 120px; height: 120px"
                :src="item.file_url">
              </el-image>
              <video
                v-if="item.file_type == 2"
                fit="cover"
                controls
                height="120px"
                width="120px"
                :src="item.file_url">
              </video>
            </div>
            <div class="info">
              <div :class="itemIndex == index ? 'title' : 'title'">{{item.title}}</div>
              <div class="flex space-between">
                <div class="time"><i class="el-icon-time"></i>{{item.create_time}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="right" ref="right" v-html="currContent"></div>
      </div>
    </div>
    <template v-else >
      <div style="text-align:center;">
        <el-divider></el-divider>
        <div style="text-align:center;min-height:500px;">暂未添加帮助</div>
      </div>
    </template>


  </div>
</template>

<script>
import { mixinTable } from '@/mixins/table.js'
export default {
  name: '',
  mixins: [mixinTable],
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      isChange: false, // 编辑时为true
      sortIndex:1,
      table: {
        loading: false,
        params: {
          sort:1, //排序 1:最新 2:热门
          keyword:'',
          page:1,
          count: 10
        },
        data: [],
        total:0
      },
      dialogFormVisible: false,
      itemIndex: 0,
      currContent: '',// 当前选择的内容
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.get('/admin/course/helpList', {params: this.table.params}).then(res => {
        if(res.code == 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.currContent = res.data.list[0].content;
        }
      })
    },
    // 最新/热门
    handleSort(index) {
      this.sortIndex = index;
      this.table.params.sort = index;
      this.getTable()
    },
    // 点击帮助列表
    handleList(item,index) {
      this.itemIndex = index; // 当前索引
      this.$refs.right.scrollTop = 0
      this.currContent = item.content; // 内容
      this.$http.get('/admin/course/view',{params:{id:item.id}}).then(res => {}) //调详情接口目的为记录浏览量
    },
    // 新增/编辑
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if(!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 删除
    remove(row) {
      this.setConfirm(
        `确认删除该条帮助？`,
        {  id: row.id },
        "/platform/course/delHelp"
      );
    }
    
  }
}
</script>
<style scoped>
.mt10 {
  margin-top:10px;
}
.flex {
  display: flex;
}
.sort {
  display: inline-block;
  width: 80px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 20px;
  border: solid 1px #2cb167;
  cursor: pointer;
}
.sortActive {
  background: #2cb167;
  color: #fff;
}
.space-between {
  justify-content: space-between;
}
.content {
  height: 600px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6), 0 2px 4px 0 rgba(232, 237, 250, 0.5);
}
.left {
  width: 40%;
  max-width: 45px;
  min-width: 450px;
  margin-right: 20px;
  overflow-y: scroll;
}
.right {
  overflow-y: scroll;
  padding: 10px;
}
::-webkit-scrollbar {
  width: 0px; /* 设置滚动条宽度 */
}
.left .list {
  padding: 10px;
  border: solid 1px #eee;
  display: flex;
  margin-top: 10px;
  cursor: pointer;
}
.active {
  border: solid 1px #2cb167 !important;
  box-shadow: 0 2px 12px 0 rgba(44,177,103,.1);
  color: #2cb167;
}
.left .list:hover {
  border: solid 1px #2cb167;
}
.list .img {
  position: relative;
  margin-right: 18px;
}
.list .view_count {
  position: absolute;
  padding: 0 5px;
  bottom: 4px;
  right: 0px;
  font-size: 14px;
  color: #fff;
  background: #333;
  opacity: 0.6;
}
.list .title {
  height: 60px;
  line-height: 28px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.list .time {
  color: #909090;
  font-size: 14px;
}
.list .btnWrap {
  color: #909090;
  cursor: pointer;
}
.list .btnWrap .btn:hover {
  color: #2cb167;
  text-decoration: underline;
}
.list .cut-off {
  display: inline-block;
  margin: 0 10px;
  width: 1px;
  background: #909090;
}
.list .info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
</style>


